.BookingOption {
  background-color: #d8d8d88c;
  height: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  -webkit-box-shadow: -16px 9px 103px -49px rgba(97, 97, 97, 0.73);
  -moz-box-shadow: -16px 9px 103px -49px rgba(97, 97, 97, 0.73);
  box-shadow: -16px 9px 103px -49px rgba(97, 97, 97, 0.73);
}
#online {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

#offline {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.BookingOption:hover {
  cursor: pointer;
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.Active {
  background-color: #9a9de8;
  color: rgb(65, 65, 102);
  font-weight: 300;
  transition: background-color 0.5s;
  transform: scale(1.03);
}

.Active:hover {
  /* background-color: #aeb0da; */
  cursor: default;
  font-weight: 300;
  color: rgb(65, 65, 102);
}

.fee {
  text-align: center;
  margin-top: -20px;
  font-size: 16px;
  font-weight: 200;
  color: rgb(80, 80, 80);
}

.section-title {
  font-size: 26px !important;
}

strike {
  color: rgb(108, 55, 55);
}
